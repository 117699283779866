import styled from '@emotion/styled'
import { forwardRef, Fragment } from 'react'
import isPropValid from '@emotion/is-prop-valid'

import type * as Polymorphic from '../../utils/polymorphic'
import { LoadingDots } from '../loading-dots'
import { toMediaQueries, type ResponsiveProp } from '../../styles/responsive'

import type { ButtonSize, ButtonVariant } from './button-styles'
import { getSizeStyles, getVariantStyles } from './button-styles'
import type { ButtonOptions } from './button-types'
import { ButtonIcon } from './button-icon'

interface StyledButtonProps {
  size: ResponsiveProp<ButtonSize>
  variant: ButtonVariant
  isFullWidth: boolean
}
const StyledButton = styled('button', { shouldForwardProp: isPropValid })<StyledButtonProps>(
  ({ theme, size, variant, isFullWidth }) => ({
    borderRadius: theme.radii.full,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexShrink: 0,
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'transparent',
    userSelect: 'none',
    transitionProperty: 'box-shadow, transform, opacity, background-color, color',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease',

    '&[disabled]': {
      opacity: 0.4,
    },

    '&:not([disabled]):active': {
      transform: 'scale(0.97)',
    },
    ...toMediaQueries(size, (currentValue) => getSizeStyles(theme)[currentValue]),
    ...getVariantStyles(theme)[variant],
    ...(isFullWidth && { width: '100%' }),
  }),
)

const ButtonLoadingDots = styled(LoadingDots)({
  position: 'absolute',
  color: 'currentColor',
})

const HiddenSpan = styled.span({
  opacity: 0,
  display: 'flex',
  alignItems: 'center',
})

type PolymorphicButton = Polymorphic.ForwardRefComponent<'button', ButtonOptions>
export type ButtonProps = Polymorphic.PropsOf<PolymorphicButton>

export const Button = forwardRef((props, forwardedRef) => {
  const {
    as,
    children,
    // Only default the `type` if not using the as prop
    type = as ? undefined : 'button',
    size: sizeProp = 'md',
    variant = 'secondary',
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    disabled: hasHtmlDisabledProp,
    iconLeft,
    iconRight,
    ...rest
  } = props

  const shouldBeDisabled = isDisabled || hasHtmlDisabledProp || isLoading

  const ContentContainer = isLoading ? HiddenSpan : Fragment

  return (
    <StyledButton
      as={as}
      ref={forwardedRef}
      variant={variant}
      size={sizeProp}
      isFullWidth={isFullWidth}
      disabled={shouldBeDisabled}
      type={type}
      {...rest}
    >
      <ContentContainer>
        {iconLeft && <ButtonIcon buttonSize={sizeProp} icon={iconLeft} placement="left" />}
        {children}
        {iconRight && <ButtonIcon buttonSize={sizeProp} icon={iconRight} placement="right" />}
      </ContentContainer>
      {isLoading && <ButtonLoadingDots size="sm" data-testid="button-spinner" />}
    </StyledButton>
  )
}) as PolymorphicButton
